import I18n from 'i18n-js';
import { LanguageSelectionEnum } from 'api/HomeBase';
import { useEffect, useState } from 'react';

type Status = 'Success' | 'Failed';

export interface LocalStorageAnswers {
    [key: string]: string | string[];
}

const Auth = {
    storeAuthToken: (authToken: string): void => {
        localStorage.setItem('authToken', authToken);
    },
    getAuthToken: (): string | null => {
        return localStorage.getItem('authToken');
    },
    clearAuthToken: (): void => {
        localStorage.removeItem('authToken');
    },
};

const LocalStorage = {
    getItem<T>(key: string): T | null {
        const storageItem = localStorage.getItem(key);

        if (!storageItem) return null;

        return JSON.parse(storageItem);
    },
    setItem: (key: string, item: any | null): Status => {
        if (!item) return 'Failed';
        const parse = JSON.stringify(item);

        localStorage.setItem(key, parse);
        return 'Success';
    },
    removeItem: (key: string): void => {
        localStorage.removeItem(key);
    },
    setAnswer: (surveyId: string, questionId: string, optionId: string | string[]): void => {
        const answers = LocalStorage.getAnswers(surveyId);

        const newAnswer = {
            [questionId]: optionId,
        };

        if (answers) {
            LocalStorage.setItem(`surveyAnswers-${surveyId}`, { ...answers, ...newAnswer });
        } else {
            LocalStorage.setItem(`surveyAnswers-${surveyId}`, newAnswer);
        }
    },
    clearAnswer: (surveyId: string): void => {
        localStorage.removeItem(`surveyAnswers-${surveyId}`);
    },
    getAnswers: (surveyId: string): LocalStorageAnswers | null => {
        const result: LocalStorageAnswers | null = LocalStorage.getItem(`surveyAnswers-${surveyId}`);
        return result;
    },
};

const User = {
    setUserLanguage: (language: string): void => {
        let languageCode = '';

        if (language === 'English') languageCode = 'en';
        else if (language === 'Chinese') languageCode = 'zh';
        else if (language === 'Malay') languageCode = 'ms';

        if (languageCode) {
            LocalStorage.setItem('settingsUserLanguage', languageCode);
            I18n.locale = languageCode;
        }
    },
};

const Error = {
    setGeneralErrorWithCode: (code: string, error: string): string => {
        return error;
    },
};

const Helper = {
    getBrowserLanguage: (lang: string): LanguageSelectionEnum => {
        switch (lang) {
            case 'ms': return LanguageSelectionEnum.Malay;
            case 'zh': return LanguageSelectionEnum.Chinese;
            case 'en': return LanguageSelectionEnum.English;
            case 'en-AU': return LanguageSelectionEnum.English;
            case 'en-CA': return LanguageSelectionEnum.English;
            case 'en-GB': return LanguageSelectionEnum.English;
            case 'en-US': return LanguageSelectionEnum.English;
            case 'en-GB-oxendict': return LanguageSelectionEnum.English;
            case 'zh-TW': return LanguageSelectionEnum.Chinese;
            case 'zh-HK': return LanguageSelectionEnum.Chinese;
            case 'zh-CN': return LanguageSelectionEnum.Chinese;
            default:
                return LanguageSelectionEnum.Malay;
        }
    },
};

const Hooks = {
    useDelayUnmount: (isMounted: boolean, delayTime: number): boolean => {
        const [showDiv, setShowDiv] = useState(false);
        useEffect(() => {
            let timeoutId: NodeJS.Timeout;
            if (isMounted && !showDiv) {
                setShowDiv(true);
            } else if (!isMounted && showDiv) {
                // delay our unmount
                timeoutId = setTimeout(() => setShowDiv(false), delayTime);
            }
            return () => {
                // cleanup mechanism for effects , the use of setTimeout generate a sideEffect
                clearTimeout(timeoutId);
            };
        }, [isMounted, delayTime, showDiv]);
        return showDiv;
    },
};

export default {
    Auth,
    LocalStorage,
    User,
    Error,
    Helper,
    Hooks,
};
